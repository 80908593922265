/**
 * Loads the main banner & enables scroll
 *  I'm going to slide-in left to right
 */

 class SplashLoader {

  constructor() {
    this.run()
  }

  run() {

    // 0. Set back to top

    setTimeout(() => {
      window.scrollTo(0, 0)
    },50)

    // 1. Get banner Image

    const mainBanner = document.querySelector('[data-banner-main]'),
      bannerImageUrl = mainBanner.dataset['bannerMain']

    const getImage = new Image()
    getImage.onload = () => {
      const mainBanners = document.querySelectorAll('[data-banner-main]')
      for (const banner of mainBanners) {
        banner.style.background = `url(${bannerImageUrl}) center / cover no-repeat`
      }
      setTimeout(slideIn, 500)
    }

    getImage.src = bannerImageUrl

    // 2. Slide In

    const bannerScale = document.querySelector('[data-splash]'),
      bannerReverseScale = document.querySelector('[data-splash-reversescale]')

    const state = {
      iterations: 50, /* Fastest execution should be once per 16ms = 800 ms */
      current: 0,
    }

    const easeOutQuart = t => 1 - (--t) * t * t * t
    const easeOutOct = t => 1 - (--t) * t * t * t * t * t * t * t

    const slideIn = () => {
      state.current++

      const bannerScaleValue = easeOutQuart(state.current / state.iterations),
        reverseScaleChild = 1 / bannerScaleValue

      bannerScale.style.transform = `scaleX(${bannerScaleValue})`
      bannerReverseScale.style.transform = `scaleX(${reverseScaleChild})`

      if (bannerScaleValue === 1) return slideOutOverlay() /* Next phase */

      requestAnimationFrame(slideIn) 
    }

    // 3. Show nav, remove overlay

    const slideOutOverlay = () => {
      document.body.classList.add("scroll")
      // document.querySelector('[data-splash] [data-overlay]').classList.add("fin")
      document.querySelector('header').classList.remove("out")

      setTimeout(slideOut, 50)
    }

    // 4. Slice In

    // const sliceIn = () => {
    //   const bannerGlass = document.querySelector('[data-banner-main-shade]')

    //   bannerGlass.style.display = 'block'
    //   bannerGlass.classList.add('animate')

    // }

    const slideOut = () => {
      const bannerOverlay = document.querySelector('[data-overlay]')

      bannerOverlay.classList.add('animate-move-out-left')

    }
  }

}

export default SplashLoader