import InView from 'inview'

/**
 * Loads our slider with incremental image lazy load
 */

class FancySlider {

  constructor(container) {
    this.inProgress = false
    this.direction = 'right'

    this.slideContainer = container
    this.activePageItems = [ ...container.querySelectorAll('[data-slider-page].active .image') ]

    this.navLeft = this.slideContainer.nextElementSibling.querySelector('[data-click-left]')
    this.navRight = this.slideContainer.nextElementSibling.querySelector('[data-click-right]')
    this.navPage = [ ...this.slideContainer.nextElementSibling.querySelectorAll('[data-click-page]') ]

    this.watchNavigation()

    this.loaderTS = +new Date
    this.checkImagesInView()
  }

  watchNavigation() {
    if (this.navLeft) {
      this.navLeft.addEventListener('click', () => {
        this.switchActivePage('left')
      })
    }
    if (this.navRight) {
      this.navRight.addEventListener('click', () => {
        this.switchActivePage('right')
      })
    }
    if (this.navPage.length) {
      for (const button of this.navPage) {
        button.addEventListener('click', e => {
          const targetPageButton = e.target.dataset.clickPage ? e.target :
            (e.target.parentNode.dataset.clickPage ? e.target.parentNode :
              e.target.parentNode.parentNode)
          
          for (const button of this.navPage) {
            button.classList.remove("page-link-active")
          }

          targetPageButton.classList.add("page-link-active")
          this.switchActivePageIndex(Number(targetPageButton.dataset.clickPage))
        })
      }
    }
  }

  checkImagesInView(y) {
    for (const [idx, containerEl] of this.activePageItems.entries()) {
      this.checkImageInView(containerEl, idx)
    }
  }

  checkImageInView(containerEl, i) {
    const _this = this

    InView(containerEl, function (isInView, data) {
      if (isInView && 
        /* in top 7/8's of screen */
        data.elementOffsetTopInViewHeight < ( data.inViewHeight - data.inViewHeight / 8) 
      ) {

        /* Stagger onload */
        const diff = (+new Date - _this.loaderTS)
        const delay = 650 - (diff > 650 ? 650 : diff)
        _this.loaderTS = +new Date

        setTimeout(() => {
          _this.showContainer(containerEl, true)
        }, delay)

        this.destroy()
      }
    });
  }

  switchActivePageIndex(index) {
    const oldPage = this.slideContainer.querySelector('[data-slider-page].active')
    const nextPage = this.slideContainer.querySelector('[data-slider-page]:nth-child('+(index+1)+')')

    this.selectPage(oldPage, nextPage)
  }

  switchActivePage(dir) {
    if (this.inProgress) return false

    this.inProgress = true
    this.direction = dir === 'left' ? 'left' : 'right'

    const oldPage = this.slideContainer.querySelector('[data-slider-page].active'),
      isLeft = this.direction === 'left'

    const getNextSibling = () => {
      let sibling = isLeft ? oldPage.previousElementSibling : oldPage.nextElementSibling
      if (!sibling) {
        sibling = isLeft ? this.slideContainer.lastElementChild : this.slideContainer.firstElementChild 
      }
      return sibling
    }

    const nextPage = getNextSibling()

    this.selectPage(oldPage, nextPage)
  }

  selectPage(oldPage, nextPage) {
    oldPage.classList.remove('active')
    nextPage.classList.add('active')

    const oldPageItems = [...oldPage.querySelectorAll('.image')]
    const nextPageItems = [...nextPage.querySelectorAll('.image')]

    const clearProgress = () => this.inProgress = false

    for (const [idx, oldContainerEl] of oldPageItems.entries()) {
      const isEnd = oldPageItems.length === (idx + 1)
      const onCompletion = isEnd ? clearProgress : null
      this.swapItem(nextPageItems[idx], oldContainerEl, idx, onCompletion)
    }
  }

  swapItem(newContainerEl, oldContainerEl, idx, onCompletion) {
    setTimeout( async () => {
      await this.clearContainer(oldContainerEl)
      this.showContainer(newContainerEl)

      if (onCompletion) onCompletion()
    }, idx * 250)
  }

  showContainer(containerEl, onLoad) {
    const imageUrl = containerEl.getAttribute('data-image')
    if (imageUrl) {
      containerEl.querySelector('[data-bg]').style.background = `url(${imageUrl}) center / cover no-repeat`
    }
    const showClass = onLoad ? "in-top" : `in-out-${this.direction}`
    containerEl.classList.remove("in-out-left", "in-out-right", "out")
    containerEl.classList.add(showClass)

    this.wait(100, () => {
      containerEl.classList.add('in')
    })
  }

  clearContainer(containerEl) {
    return new Promise(resolve => {
      containerEl.classList.remove("in-top", "in-out-left", "in-out-right", "in")
      const reverseDirection = this.direction === 'left' ? 'right' : 'left'
      containerEl.classList.add(`in-out-${reverseDirection}`, "out")

      resolve()
    })
  }

  clearPage() {
    const clearItems = [...document.querySelectorAll('[data-slider-page].active .image')]
    for (const containerEl of clearItems) {
      this.clearContainer(containerEl)
    }
  }

  wait(msWait, cb) {
    if (!cb) throw new Error('Callback missing')

    let startTime = null;
    const loop = (time) => {
      if (startTime === null) startTime = time
      const duration = time - startTime

      if (duration < msWait) {
        requestAnimationFrame(loop)
        return false
      }

      cb()
    }
    requestAnimationFrame(loop)
  }

}

export default FancySlider