class Form {

  constructor() {
    this.labels = [...document.querySelectorAll('[data-form] label')]
    this.inputs = [...document.querySelectorAll('[data-form] input')]

    this.el = {
      form: document.querySelector('[data-form]'),
      error: document.querySelector('[data-form-error]'),
      success: document.querySelector('[data-form-success]')
    }

    this.fields = []

    if (this.el.form) {
      this.el.submit = this.el.form.querySelector('[data-submit]')
      this.init()
    }
  }

  init() {
    /* Input label styles */
    // for (const [idx, input] of this.inputs.entries()) {
    //   if (input.value) {
    //     this.labels[idx].classList.add('_on')
    //   }

    //   input.addEventListener('focus', () => {
    //     this.labels[idx].classList.add('_on')
    //   })

    //   input.addEventListener('blur', () => {
    //     if (!input.value) {
    //       this.labels[idx].classList.remove('_on')
    //     }
    //   })
    // }

    /* Disable native browser validation */
    this.el.form.setAttribute("novalidate", "");

    /* Handle submit */
    this.el.submit.addEventListener('click', e => {
      e.preventDefault()

      if (this.validate()) {
        this.onSubmit()
      }
    })
  }

  validate() {
    return true
  }

  onSubmit() {
    this.clearResponse()
    this.el.form.classList.add("sending")
  }

  clearResponse() {
    this.el.success.innerText = ''
    this.el.error.innerText = ''
    this.el.success.style.display = 'none'
    this.el.error.style.display = 'none'
  }

  handleSuccess(msg, asHtml) {
    this.el.form.classList.remove("sending")
    const insert = asHtml ? "innerHTML" : "innerText"
    this.el.success[insert] = msg || "Registration successful"
    this.el.success.style.display = 'block';
    this.el.error.style.display = 'none';
  }

  handleError(msg, asHtml) {
    this.el.form.classList.remove("sending")
    const insert = asHtml ? "innerHTML" : "innerText"
    this.el.error[insert] = msg || "Sorry, there was an unknown error"
    this.el.error.style.display = 'block';
  }

}

export default Form