import Http from './Http'
import Form from './Form'
import $ from 'jquery';

class Mailchimp extends Form {

  constructor() {
    super()

    this.fields = [
      { name: "FNAME", required: true },
      { name: "LNAME", required: true },
      { name: "PHONE", required: false },
      { name: "EMAIL", required: true },
    ]
  }

  init() {
    super.init()
  }

  validate() {
    const inputs = [...this.el.form.querySelectorAll("input")]
    for (const input of inputs) {
      input.classList.remove("_err")
    }

    let err = 0;
    for (const field of this.fields) {
      if (!field.required) continue;

      const input = this.el.form.querySelector("[name=" + field.name + "]");
      if (!input.value.trim().length) {
        input.classList.add("_err");
        err++;
      }
    }

    if (err) {
      this.el.error.innerText = "Sorry, some fields are still required"
      this.el.error.style.display = "block"
      return false;
    }

    return true;
  }

  onSubmit() {
    super.onSubmit()
    const self = this;

    const formParams = new URLSearchParams(new FormData(this.el.form)).toString()
    const url = this.el.form.getAttribute('action') + "&" + formParams

    $.ajax({
      url: url + "&c=?",
      method: 'GET',
      dataType: 'jsonp',
      success: function(data) {
        if (data.result !== "success") {
          const err = data.msg.split(" - ")
          return self.handleError((err[1] || err[0]) || "Sorry, there was an unknown error", true)
        }
        self.handleSuccess(data.msg || "Thank you, we'll be in touch.", true)
      },
      fail: function(err) {
        console.log(err)
        return self.handleError("Sorry, there was an unknown error")
      }
    })

    // new Http(url, null, { method: 'GET' })
    //   .then(data => {
    //     if (data.result !== "success") {
    //       return this.handleError(data.msg || "Sorry, there was an unknown error")
    //     }
    //     this.handleSuccess(data.msg || "Thank you, we'll be in touch.")

    //   })
    //   .catch(err => {
    //     return this.handleError("Could not connect to the registration server. Please try again later.")

    //   })
    //   .finally(() => {
    //     /* TBD: Hide loading */

    //   })
  }

}

export default Mailchimp