import InView from 'inview'

/**
 * Loads any container when in-view
 */

class FancyLoader {

  constructor(selector) {
    this.selector = selector
    this.containers = [ ...document.querySelectorAll('[' + selector +']') ]

    this.checkImagesInView()
  }

  checkImagesInView() {
    for (const [idx, containerEl] of this.containers.entries()) {
      this.checkImageInView(containerEl)
    }
  }
  
  checkImageInView(containerEl) {
    const _this = this;

    InView(containerEl, function (isInView, data) {
      if (
        containerEl.nodeName === "PICTURE" &&
        !containerEl.getAttribute('data-picture-fin') &&
        /* anywhere above next "screen" (below current view) */
        data.elementOffsetTop < (data.windowScrollTop + data.inViewHeight)
      ) {
        _this.loadPictures(containerEl)
      }

      if (isInView &&
        /* in top 7/8's of screen */
        data.elementOffsetTopInViewHeight < (data.inViewHeight - data.inViewHeight / 8)
      ) {
        _this.showContainer(containerEl)
        this.destroy()
      }
    });
  }

  loadPictures(pictureEl) {
    const pictures = pictureEl.querySelectorAll('[data-srcset]')
    for (const el of pictures) {
      el.setAttribute('srcset', el.dataset.srcset)
    }
    pictureEl.setAttribute('data-picture-fin', true)
  }

  showContainer(containerEl) {
    const imageUrl = containerEl.getAttribute(this.selector)

    if (imageUrl) {
      containerEl.style.background = `url(${imageUrl}) center / cover no-repeat`
    }

    if (containerEl.dataset.hasOwnProperty('hidden')) {

      const animateClass = containerEl.dataset.hidden ? containerEl.dataset.hidden : "fade-in-slide"
      containerEl.classList.add("animate", `${animateClass}-start`)
      delete containerEl.dataset.hidden

      const SVGs = containerEl.querySelectorAll('svg')
      const svgContainers = SVGs ? [...SVGs] : []
      if (svgContainers.length) {
        this.showSVGs(svgContainers)
      }
      const EMs = containerEl.querySelectorAll('[data-em]')
      const emContainers = EMs ? [...EMs] : []
      if (emContainers.length) {
        this.convertTextToEm(emContainers)
      }

      setTimeout(() => {
        containerEl.classList.add(animateClass)
      }, 50)
    }
  }

  showSVGs(containers) {
    for (const svg of containers) {
      svg.classList.add('_show')
    }
  }

  convertTextToEm(containers) {
    for (const container of containers) {
      const innerText = container.innerText
      const characters = innerText.split('')
      container.innerHTML = ''

      if (characters.length) {
        for (const char of characters) {
          const emNode = document.createElement('em')
          emNode.innerText = char
          container.appendChild(emNode)
        }
      }
    }
  }

}

export default FancyLoader