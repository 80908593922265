const ts = [ ...document.querySelectorAll('[data-toggle]') ]
const share = [ ...document.querySelectorAll('[data-share]') ]

for (const toggle of ts) {
  toggle.addEventListener('click', toggleHandler)
}

for (const link of share) {
  link.addEventListener('click', shareHandler)
}

document.addEventListener('click', (e) => {
  toggleHandler(e, true)
})

const state = {
  on: false
}

function toggleHandler(e, hide) {
  e.stopPropagation()
  state.on = hide ? false : !state.on
  const action = state.on ? "add" : "remove"

  for (const toggle of ts) {
    toggle.parentNode.classList[action]("on")
  }
}

function shareHandler() {
  const url = encodeURI(window.location.href)
  switch (this.dataset.share) {
    case 'fb':
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, `pop`, `width=600, height=400, scrollbars=no`);
      break;
    case 'pinterest':
      window.open(`http://pinterest.com/pin/create/button/?url=${url}&media=${url}/static/88-laurens-street-north-melbourne.jpg&description=88%20Laurens%20Street`);
      break;
  }
}