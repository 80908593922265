/**
 * Navigation menu
 *  Handles show menu + scroll to anchor point
 */

 class Navigation {

  constructor() {
    this.open = false
    this.hamburger = document.querySelector('[data-navigation]')
    this.links = [...document.querySelectorAll('[data-nav-link]')]
    this.nav = document.querySelector('[data-nav-main]')
    this.totop = document.querySelector('[data-totop]')

    this.header = document.querySelector('header')
    this.headerHeight = this.header.offsetHeight
    this.bgOn = false
    this.windowY = 0

    this.init()
  }

  init() {
    this.hamburger.addEventListener('click', (e) => {
      e.stopPropagation()
      this.onClick()
    })

    document.addEventListener('click', () => {
      this.switch(false)
    })

    for (const link of this.links) {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        this.moveTo(link)
      })
    }

    this.totop.addEventListener('click', (e) => {
      e.preventDefault()
      this.moveTo(this.links[0])
    })

    this.watchScroll()
  }

  watchScroll() {
    window.onscroll = () => this.updateNavBg()
  }

  updateNavBg() {
    if (window.scrollY > 200 && !this.bgOn) {
      this.bgOn = true
      this.header.classList.add("scroll")
    } else if (window.scrollY < 100 && this.bgOn) {
      this.bgOn = false
      this.header.classList.remove("scroll")
    }
  }

  onClick() {
    this.switch(!this.open)
  }

  moveTo(e) {
    const page = e.getAttribute('href').replace('#', '')
    const anchor = document.querySelector(`#page-${page}`)
    const offset = anchor.offsetTop - (Number(page) > 1 ? this.headerHeight : 0)

    // scrollTo(offset)
    window.scrollTo({top: offset, behavior: 'smooth'})
  }

  switch(bool) {
    this.open = bool

    const action = this.open ? "add" : "remove"
    this.hamburger.classList[action]("is-active")
    this.nav.classList[action]("is-active")
  }

}

export default Navigation