import SplashLoader from './lib/SplashLoader'
import Navigation from './lib/Navigation'
import FancyLoader from './lib/FancyLoader'
import FancySlider from './lib/FancySlider'
import Parallax from './lib/Parallax'
import Mailchimp from './lib/Mailchimp'
import $ from 'jquery';
import './lib/share.js'

class App {

  constructor() {

    /* Wait a slight of time to avoid skipping class-based animation */
    setTimeout(() => { this.run() }, 50)

  }

  run() {

    document.body.classList.add('ready') /* Fade-in when ready to avoid FOUC */

    new SplashLoader()
    // new Parallax()
    new Navigation()
    new Mailchimp()

    new FancyLoader("data-content")

    const sliders = [...document.querySelectorAll("[data-slider]")]
    for (const slider of sliders) {
      new FancySlider(slider)
    }

    this.watchVideos()

    // window._app = this
    // window._fancyload = fancyload
    // window._fancyslide = fancyslide
  }

  watchVideos() {
    window.jQuery = window.$ = require('jquery');

    require('modaal')
    require('modaal/dist/css/modaal.min.css')

    $('.video').modaal({
      type: 'video',
      before_open: () => {
        $('body').css({ 'overflow-y': 'hidden' })
      },
      after_close: () => {
        $('body').css({ 'overflow-y': 'overlay' })
      }
    })
  }

}

export default App